import React, { useEffect, useState } from 'react';
import { Col, Form, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { panelService } from 'services/api';

const TestComponent: React.FC = () => {
  const [colors, setColors] = useState<{ colorId: string; name: string }[]>([]);

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const colorsData = await panelService.listColors();
        setColors(colorsData);
      } catch (error) {
        console.error('Erro ao buscar cores:', error);
      }
    };

    fetchColors();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <h1>Test Page</h1>
          <p>Esta é uma página de teste.</p>
          My Calendars
          {colors.map((color) => (
            <Form.Check id={color.colorId} key={color.colorId}>
              <Form.Check.Input type="checkbox" className={`bg-${color.colorId} border-${color.colorId}`} />
              <Form.Check.Label>{color.name}</Form.Check.Label>
            </Form.Check>
          ))}
        </Col>
        <Col xs={12} md={6}>
          <h1>Outros testes</h1>
          <Link to="#!" className="fw-bold fs-9" >
            Adicionar novo calendário
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default TestComponent;