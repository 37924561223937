import { useState, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { authService } from 'services/api';
import { useAuth } from 'providers/AuthProvider';
import InputMask from 'react-input-mask';

const SignInForm = ({ layout }: { layout: string }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    instance: 'axfaust'
  });
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    const savedName = localStorage.getItem('name');
    if (savedName) {
      setFormData(prev => ({ ...prev, name: savedName }));
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const sanitizedPhone = formData.phone.replace(/\D/g, '');

    if (form.checkValidity() === false || sanitizedPhone.length !== 11) {
      e.stopPropagation();
      if (sanitizedPhone.length !== 11) {
        setError('O número de celular deve ter 11 dígitos.');
      }
    } else {
      try {
        const response = await authService.login({
          ...formData,
          phone: sanitizedPhone
        });
        login(response.token, response.userData);
        
        localStorage.setItem('name', formData.name);
        setError('');
      } catch (error) {
        setError('Credenciais inválidas');
      }
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {error && <div className="alert alert-danger">{error}</div>}
      
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label>Nome</Form.Label>
        <Form.Control
          type="text"
          value={formData.name}
          onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
          required
        />
        <Form.Control.Feedback type="invalid">
          Por favor, forneça um nome válido.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPhone">
        <Form.Label>Celular</Form.Label>
        <InputGroup hasValidation>
          <InputMask
            mask="(99) 99999-9999"
            value={formData.phone}
            onChange={e => setFormData(prev => ({ ...prev, phone: e.target.value }))}
            className="form-control"
            required
          />
          <Form.Control.Feedback type="invalid">
            {error || 'Por favor, forneça um número de celular válido.'}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Button type="submit" className="btn btn-primary w-100">
        Entrar
      </Button>
    </Form>
  );
};

export default SignInForm;
