import axios from 'axios';
import { FileUploadPayload } from 'utils/fileUpload';

const api = axios.create({
  baseURL: `http://${window.location.hostname}:3000`
});

console.log(`Backend: http://${window.location.hostname}:3000`);

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export interface LoginData {
  name: string;
  phone: string;
  instance: string;
}

export interface CreateDealColumnData {
  boardId: string;
  title: string;
  totalValue: number;
}

export interface User {
  userId: string;
  fullName: string;
  email: string;
  accessType: string;
  isActive: boolean;
  lastLogin: string;
  contact: string;
}

export interface DealCategory {
  dealCategoryId: string;
  name: string;
}

export interface CampaignSource {
  dealCampaignSourceId: string;
  name: string;
}

export interface DealType {
  dealTypeId: string;
  name: string;
}

export interface LeadSource {
  leadSourceId: string;
  name: string;
}

export interface Industry {
  dealIndustryId: string;
  name: string;
}

export interface CreateDealData {
  columnId: string;
  boardId: string;
  companyId: string | null;
  title: string;
  contactName: string;
  dealCode: string;
  categoryId: string;
  leadSourceId: string;
  campaignSourceId: string;
  typeId: string;
  industryId: string;
  responsibleUserId: string;
  totalValue: number;
  priority: string;
  deliveryDate: string;
  probability: number;
  stage: string;
  phoneNumber?: string;
  emailAddress?: string;
}

export interface MoveDealData {
  sourceColumnId: string;
  destinationColumnId: string;
  newIndex: number;
}

export interface DealDetails {
  dealId: string;
  title: string;
  contactName: string;
  dealCode: string;
  totalValue: string;
  priority: string;
  deliveryDate: string;
  probability: number;
  stage: string;
  categoryId: string;
  industryId: string;
  leadSourceId: string;
  campaignSourceId: string;
  typeId: string;
  responsibleUserId: string;
  industry: { name: string };
  category: { name: string };
  leadSource: { name: string };
  campaignSource: { name: string };
  type: { name: string };
  responsibleUser: {
    fullName: string;
    email: string;
  };
  // ... outros campos conforme necessário
}

export interface DealNote {
  noteId: string;
  dealId: string;
  text: string;
  createdBy: string;
  updatedBy?: string;
  createdAt: string;
  updatedAt: string;
  userId?: string;
}

export interface EventData {
  calendarId: string;
  type: string;
  title: string;
  startAt: string;
  endAt: string;
  description: string;
  allDay: boolean;
  participants: { type: string; participantId: string }[];
}

export interface NewCalendarData {
  name: string;
  description: string;
  colorId: string;
  isPrivate: boolean;
  isDefault: boolean;
}

export const authService = {
  login: async (credentials: LoginData) => {
    const response = await api.post('/auth/login', credentials);
    return response.data;
  },
  
  logout: () => {
    localStorage.removeItem('token');
  }
};

export const boardService = {
  getBoards: async () => {
    const response = await api.get('/board');
    return response.data;
  },
  
  getBoard: async (boardId: string) => {
    const response = await api.get(`/board/${boardId}`);
    return response.data;
  },
  
  addColumn: async (data: CreateDealColumnData) => {
    const response = await api.post('/column', data);
    return response.data;
  },
  
  updateColumn: async (data: {
    columnId: string;
    boardId: string;
    title: string;
    totalValue: number;
  }) => {
    const response = await api.patch(`/column/${data.columnId}`, data);
    return response.data;
  },
  
  deleteColumn: async (columnId: string) => {
    const response = await api.delete(`/column/${columnId}`);
    return response.data;
  },
  
  moveColumn: async (columnId: string, data: { 
    newIndex: number;
  }) => {
    const response = await api.patch(`/column/${columnId}/move`, data);
    return response.data;
  },
  
  createBoard: async (data: { title: string; description: string; visibility: string; coverImage: string }) => {
    try {
      const response = await api.post('/board', data);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar board:', error);
      throw error;
    }
  }
};

export const customerService = {
  getCustomers: async () => {
    const response = await api.get('/customer');
    return response.data;
  },
  addLead: async (leadData: {
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    jobTitle: string;
    photoUrl: string;
  }) => {
    const response = await api.post('/customer', leadData);
    return response.data;
  }
};

export const dealFormService = {
  getUsers: async (): Promise<User[]> => {
    const response = await api.get('/panel/user');
    return response.data;
  },
  
  getDealCategories: async (): Promise<DealCategory[]> => {
    const response = await api.get('/panel/aux/dealcategory');
    return response.data;
  },
  
  getCampaignSources: async (): Promise<CampaignSource[]> => {
    const response = await api.get('/panel/aux/campaignsource');
    return response.data;
  },
  
  getDealTypes: async (): Promise<DealType[]> => {
    const response = await api.get('/panel/aux/dealtype');
    return response.data;
  },
  
  getLeadSources: async (): Promise<LeadSource[]> => {
    const response = await api.get('/panel/aux/leadsource');
    return response.data;
  },
  
  addUser: async (userData: { fullName: string; email: string; password: string; contact: string; }) => {
    const response = await api.post('/panel/user', userData);
    return response.data;
  },
  updateUser: async (userId: string, userData: { fullName: string; email: string; contact: string; profileImage: string; }) => {
    const response = await api.patch(`/panel/user/${userId}`, userData);
    return response.data;
  },
  deleteUser: async (userId: string) => {
    const response = await api.delete(`/panel/user/${userId}`);
    return response.data;
  },
  getUserById: async (userId: string) => {
    const response = await api.get(`/panel/user/${userId}`);
    return response.data;
  },
  activateUser: async (userId: string) => {
    const response = await api.patch(`/panel/user/${userId}/activate`);
    return response.data;
  },
  
  getDealIndustries: async () => {
    try {
      const response = await api.get('/panel/aux/dealindustry');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar indústrias:', error);
      throw error;
    }
  }
};

export const dealService = {
  createDeal: async (data: CreateDealData) => {
    const response = await api.post('/deal', data);
    return response.data;
  },
  
  moveDeal: async (dealId: string, data: MoveDealData) => {
    const response = await api.patch(`/deal/${dealId}/move`, data);
    return response.data;
  },
  
  getDeal: async (dealId: string): Promise<DealDetails> => {
    const response = await api.get(`/deal/${dealId}`);
    return response.data;
  },
  
  updateDeal: async (dealId: string, updatedData: Partial<DealDetails>) => {
    try {
      const response = await api.patch(`/deal/${dealId}`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar o negócio:', error);
      throw error;
    }
  },

  listDealNotes: async (dealId: string) => {
    const response = await api.get(`/deal/${dealId}/note`);
    return response.data;
  },

  createDealNote: async (dealId: string, text: string) => {
    const response = await api.post(`/deal/${dealId}/note`, { text });
    return response.data;
  }
};

export const userService = {
  updateUserAvatar: async (userId: string, payload: FileUploadPayload) => {
    const response = await api.patch(`/panel/user/${userId}/avatar`, payload);
    return response.data;
  },

  updateUserCover: async (userId: string, payload: FileUploadPayload) => {
    const response = await api.patch(`/panel/user/${userId}/cover`, payload);
    return response.data;
  }
};

export const calendarService = {
  getCalendar: async (calendarId: string, startDate: string, endDate: string) => {
    try {
      const response = await api.get(`/calendar/${calendarId}`, {
        params: { startDate, endDate }
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar o calendário:', error);
      throw error;
    }
  },

  listCalendars: async (startDate: string, endDate: string) => {
    try {
      const response = await api.get('/calendar', {
        params: { startDate, endDate }
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao listar calendários:', error);
      throw error;
    }
  },

  createEvent: async (eventData: EventData) => {
    try {
      const response = await api.post('/event', eventData);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar evento:', error);
      throw error;
    }
  },

  createCalendar: async (calendarData: {
    name: string;
    description: string;
    colorId: string;
    isPrivate: boolean;
    isDefault: boolean;
  }) => {
    try {
      const response = await api.post('/calendar', calendarData);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar calendário:', error);
      throw error;
    }
  }
}; 

export const panelService = {
  listColors: async () => {
    try {
      const response = await api.get('/panel/aux/color');
      return response.data;
    } catch (error) {
      console.error('Erro ao listar cores:', error);
      throw error;
    }
  }
};