import PhoenixOffcanvas from 'components/base/PhoenixOffcanvas';
import Section from 'components/base/Section';
import { useState } from 'react';
import { Button, Col, Pagination, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbar from 'components/base/Scrollbar';
import ProductFilterItems from 'components/modules/e-commerce/products-filter/ProductFilterItems';
import ProductCard from 'components/common/ProductCard';
import { allProducts } from 'data/e-commerce/products';
import {
  faChevronLeft,
  faChevronRight,
  faFilter
} from '@fortawesome/free-solid-svg-icons';

const ProductsFilter = () => {
  return (
    <div>
      <Section className="pt-5 pb-9">
        
        <Row>
          
          <Col lg={12} xxl={12}>
            <Row className="gx-3 gy-6 mb-8">
              {allProducts.map(product => (
                <Col xs={12} sm={6} md={4} xxl={2} key={product.id}>
                  <div className="product-card-container h-100">
                    <ProductCard product={product} />
                  </div>
                </Col>
              ))}
            </Row>

            
          </Col>
        </Row>
      </Section>
    </div>
  );
};

export default ProductsFilter;
