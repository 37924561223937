import MainLayout from 'layouts/MainLayout';
import Starter from 'pages/pages/Starter';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import Ecommerce from 'pages/dashboard/ecommerce';
import EcommerceLayout from 'layouts/EcommerceLayout';
import Cart from 'pages/apps/e-commerce/customer/Cart';
import Checkout from 'pages/apps/e-commerce/customer/checkout/Checkout';
import ProductsFilter from 'pages/apps/e-commerce/customer/ProductsFilter';
import Profile from 'pages/apps/e-commerce/customer/Profile';
import AddProduct from 'pages/apps/e-commerce/admin/AddProduct';
import Products from 'pages/apps/e-commerce/admin/Products';
import Customers from 'pages/apps/e-commerce/admin/Customers';
import Orders from 'pages/apps/e-commerce/admin/Orders';
import OrderDetails from 'pages/apps/e-commerce/admin/OrderDetails';
import Refund from 'pages/apps/e-commerce/admin/Refund';
import CustomerDetails from 'pages/apps/e-commerce/admin/CustomerDetails';
import ProjectManagement from 'pages/dashboard/ProjectManagement';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import App from 'App';
import Crm from 'pages/dashboard/Crm';
import { Suspense, lazy } from 'react';
import { AuthProvider } from 'providers/AuthProvider';
import PrivateRoute from 'components/auth/PrivateRoute';
import Users from 'pages/apps/panel/Users';
import UserSettings from 'pages/apps/panel/UserSettings';
import TestComponent from 'pages/test/TestComponent';

const routes: RouteObject[] = [
  {
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    children: [
      /* {
        path: '/',
        element: (
          <PrivateRoute>
            <MainLayoutProvider>
              <MainLayout />
            </MainLayoutProvider>
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Ecommerce />
          },
          {
            path: '/dashboard',
            children: [
              {
                path: 'project-management',
                element: <ProjectManagement />
              },
              {
                path: 'crm',
                element: <Crm />
              }
            ]
          },
          {
            path: '/apps',
            children: [
              {
                path: 'panel/',
                children: [
                  {
                    path: 'users',
                    element: <Users />
                  },
                  {
                    path: 'user-profile',
                    element: <UserSettings />
                  }
                ]
              },
              {
                path: 'e-commerce/admin',
                children: [
                  {
                    path: 'add-product',
                    element: <AddProduct />
                  },
                  {
                    path: 'products',
                    element: <Products />
                  },
                  {
                    path: 'customers',
                    element: <Customers />
                  },
                  {
                    path: 'orders',
                    element: <Orders />
                  },
                  {
                    path: 'order-details',
                    element: <OrderDetails />
                  },
                  {
                    path: 'refund',
                    element: <Refund />
                  },
                  {
                    path: 'customer-details',
                    element: <CustomerDetails />
                  }
                ]
              },
            ]
          },
        ]
      }, */

      {
        element: <EcommerceLayout />,
        path: '/',
        children: [
          {
            path: '',
            element: <ProductsFilter />
          },
          {
            path: 'perfil',
            element: <Profile />
          },
          {
            path: 'carrinho',
            element: <Cart />
          },
          {
            path: 'checkout',
            element: <Checkout />
          }
        ]
      },

      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/apps/',
        children: [
          {
            path: 'login',
            element: <SimpleSignIn />
          },
        ]
      },
      {
        path: '/test',
        element: <TestComponent />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
