import { createContext, useContext, useEffect, useState, PropsWithChildren } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface UserData {
  organizationId: string;
  userId: string;
  accessType: string;
  fullName: string;
  email: string;
  profileImage?: string;
  coverImage?: string;
  lastLogin: string;
  Organization: {
    organizationId: string;
    name: string;
    instance: string;
    createdAt: string;
    updatedAt: string;
  };
}

interface AuthContextType {
  isAuthenticated: boolean;
  userData: UserData | null;
  login: (token: string, data: UserData) => void;
  logout: () => void;
  updateUserData: (newData: UserData) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserData = localStorage.getItem('userData');
    if (token && storedUserData) {
      setIsAuthenticated(true);
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const login = (token: string, userData: UserData) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userData', JSON.stringify(userData));
    setIsAuthenticated(true);
    setUserData(userData);
    navigate('/dashboard/crm');
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    setIsAuthenticated(false);
    setUserData(null);
    navigate('/apps/login');
  };

  const updateUserData = (newData: UserData) => {
    setUserData(newData);
    localStorage.setItem('userData', JSON.stringify(newData));
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userData, login, logout, updateUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 