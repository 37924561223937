export const getBackgroundColor = (userId?: string): string => {
  if (!userId) return '#e9ecef';
  const hex = userId.slice(-6);
  const rgb = hex.match(/../g) || [];
  const darkerRgb = rgb.map(color => Math.max(0, parseInt(color, 16) - 64).toString(16).padStart(2, '0'));
  const darkerHex = darkerRgb.join('');
  return `#${darkerHex}`;
};

export const getTextColor = (userId?: string): string => {
  if (!userId) return '#212121';
  const hex = userId.slice(-6);
  const rgb = hex.match(/../g) || [];
  const lighterRgb = rgb.map(color => Math.min(255, parseInt(color, 16) + 64).toString(16).padStart(2, '0'));
  const lighterHex = lighterRgb.join('');
  return `#${lighterHex}`;
};

export const getInitials = (name?: string): string => {
  if (!name) return '';
  const nameParts = name.trim().split(' ');
  const firstName = nameParts[0] || '';
  const lastName = nameParts[nameParts.length - 1] || '';
  return `${firstName.charAt(0)}${lastName !== firstName ? lastName.charAt(0) : ''}`.toUpperCase();
}; 